#value-proposition {
    max-width: 100vw;
    height: auto;
    padding: 3rem;

    h2 {
        font-size: 4.20rem;
        text-align: center;
        padding-bottom: 3rem;
    }

    h3 {
        font-family: 'Trispace-Bold', monospace;
        font-size: 3rem;
        margin: 1rem;
    }

    p {
        font-size: 2rem;
        letter-spacing: 0.15rem;
        line-height: 1.7;
    }
}

/* Adjust size for tablets */
@media (max-width: 768px) {
    #value-proposition {
        h2 {
            font-size: 3rem;
        }

        h3 {
            font-size: 2rem;
        }

        p {
            font-size: 1.3rem;
        }
    }
}

.value-proposition-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 1rem;
}

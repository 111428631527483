#social-media {
    max-width: 100vw;
    height: auto;
    padding: 3rem;
    text-align: center;

    .heading-spacing {
        font-family: 'Trispace', monospace;
        margin-top: -3rem;
    }

    h2 {
        font-size: 4.20rem;
        text-align: center;
        padding-bottom: 3rem;
    }

    p {
        font-size: 2rem;
        letter-spacing: 0.15rem;
        line-height: 1.7;
    }

    svg {
        margin: 1rem;
    }
}

/* Adjust size for tablets */
@media (max-width: 768px) {
    #social-media {
        h2 {
            font-size: 3rem;
        }

        .heading-spacing {
            font-family: 'Trispace', monospace;
            font-size: 2rem;
        }

        p {
            font-size: 1.3rem;
        }

        svg {
            margin: 0.5rem;
        }
    }
}

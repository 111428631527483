
@font-face {
    font-family: 'Rubik Bubbles';
    src: url('../assets/fonts/RubikBubbles-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Comfortaa';
    src: url('../assets/fonts/Comfortaa-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Comfortaa-Bold';
    src: url('../assets/fonts/Comfortaa-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Comfortaa-Light';
    src: url('../assets/fonts/Comfortaa-Light.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Comfortaa-Medium';
    src: url('../assets/fonts/Comfortaa-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Trispace';
    src: url('../assets/fonts/Trispace-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Trispace-Bold';
    src: url('../assets/fonts/Trispace-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Trispace-Light';
    src: url('../assets/fonts/Trispace-Light.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

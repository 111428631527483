a, a:visited {
    color: var(--primary-color) !important;
    text-decoration: none !important;
}

a:hover {
    color: var(--secondary-color) !important;
}

a:active {
    color: var(--tertiary-color) !important;
}

button,
button:visited {
    background-color: var(--primary-color) !important;
    font-size: 1.5rem !important;
    border: none !important;
    box-shadow: 1px 4px 4px var(--text-color) !important;
    /* For Android shadow property */
    elevation: var(5) !important;
}

button:hover {
    background-color: var(--secondary-color) !important;
}

button:active {
    background-color: var(--tertiary-color) !important;
}

input {
    border: 2px solid var(--primary-color) !important;
    border-radius: 5px !important;
    padding: 0.5rem !important;
    font-size: 1.5rem !important;
    color: var(--text-color)!important;
}

input:hover {
    border-color: var(--secondary-color) !important;
}

input:focus {
    outline: none !important; /* Remove default outline */
    box-shadow: 1px 4px 4px var(--text-color) !important;
    /* For Android shadow property */
    elevation: var(5) !important;
}

input:valid {
    border-color: var(--primary-color) !important;
}

input:invalid {
    border-color: var(--secondary-color) !important;
}

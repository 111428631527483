#key-features {
    max-width: 100vw;
    height: auto;
    padding: 3rem;

    h2 {
        font-size: 4.20rem;
        text-align: center;
        padding-bottom: 3rem;
    }

    p {
        font-size: 2rem;
        letter-spacing: 0.15rem;
    }

    .mock-img {
        width: 90%;
        height: auto;
        padding-bottom: 4rem;
    }
}

/* Adjust size for tablets */
@media (max-width: 768px) {
    #key-features {
        h2 {
            font-size: 3rem;
        }

        p {
            font-size: 1.3rem;
        }
    }
}

.key-feat-row {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;

    img {
        width: 70%;
        height: auto;
    }
}

.mock-img-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

#hero {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 100vh;
  text-align: center;
}

.hero-content h1 {
  font-family: 'Rubik Bubbles', cursive;
  font-size: 7rem; /* Default size for larger screens */
  text-shadow: 1px 4px 4px #003820;
  /* For Android shadow property */
  elevation: var(5);
}

/* Adjust size for tablets */
@media (max-width: 768px) {
  .hero-content h1 {
    font-size: 6rem;
  }
}

/* Adjust size for mobile devices */
@media (max-width: 480px) {
  .hero-content h1 {
    font-size: 5rem;
  }
}

.slogan {
  font-size: 1.5rem;
  margin-top: 0.5rem;
}

.scroll-button {
  width: 100px;
  height: 80px;
}

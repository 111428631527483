#mobile-download {
    max-width: 100vw;
    height: auto;
    padding: 3rem;
    text-align: center;

    h2 {
        font-size: 4.20rem;
        text-align: center;
        padding-bottom: 3rem;
    }

    p {
        font-size: 2rem;
        letter-spacing: 0.15rem;
        line-height: 1.7;
    }
}

.stores-apple {
    width: 250px;
    height: auto;
}

.stores a:hover img {
    transform: scale(1.1);
    transition: transform 0.3s ease;
}

/* Adjust size for tablets */
@media (max-width: 768px) {
    #mobile-download {
        h2 {
            font-size: 3rem;
        }

        p {
            font-size: 1.3rem;
        }
    }
}

.stores {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .stores-text {
        background-color: #269165;
        color: #FEF7FE;
        padding: 0.25rem;
        border-radius: 0.5rem;
        position: relative;
        top: -2.75rem;
        width: 17%;
        transform: rotate(-30deg);
        letter-spacing: 0.15rem;
    }
}

@media (min-width: 1700px) {
    .stores {
        .stores-text {
            top: -4.5rem;
        }
    }
}

@media (min-width: 768px) {
    .stores {
        .stores-text {
            top: -3.5rem;
        }
    }
}

@media (max-width: 768px) {
    .stores {
        .stores-text {
            top: -3rem;
            width: 50%;
        }
    }
}

@media (min-width: 500px) {
    .stores {
        .stores-text {
            top: -4rem;
        }
    }
}

@media (max-width: 400px) {
    .stores {
        .stores-text {
            top: -2.5rem;
            width: 60%;
        }
    }
}

#about {
  margin: 1rem;
  padding: 3rem;

  h2 {
    font-size: 4.20rem;
    text-align: center;
    padding-bottom: 3rem;
  }

  p {
    font-size: 2rem;
    letter-spacing: 0.15rem;
    line-height: 1.7;
  }
}

/* Adjust size for tablets */
@media (max-width: 768px) {
  #about {
    h2 {
      font-size: 3rem;
    }

    p {
      font-size: 1.75rem;
    }
  }
}

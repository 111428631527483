#terms-and-conditions {
    margin: 3rem;
    font-size: 1rem;
    color: var(--primary-color);
    line-height: 1.5;

    h1 {
        font-family: 'Rubik Bubbles', cursive;
        font-size: 4.20rem; /* Default size for larger screens */
        text-shadow: 1px 4px 4px #003820;
        /* For Android shadow property */
        elevation: var(5);
    }
}

@import './global.css';

html, body {
  margin: 0;
  padding: 0;
  font-family: 'Comfortaa-Bold', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #269165 !important;
}

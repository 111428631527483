@import './typography.css';
@import './headings.css';
@import './text.css';
@import './interactive.css';

:root {
    --primary-color: #269165;
    --secondary-color: #984800ff;
    --tertiary-color: #824790ff;
    --text-color: #003820;
    --background-color: #FEF7FE;
}

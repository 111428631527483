#coming-soon {
    max-width: 100vw;
    height: auto;
    padding: 3rem;

    h2 {
        font-size: 4.20rem;
        text-align: center;
        padding-bottom: 3rem;
    }

    p {
        font-size: 2rem;
        letter-spacing: 0.15rem;
        line-height: 1.7;
    }
}

/* Adjust size for tablets */
@media (max-width: 768px) {
    #coming-soon {
        h2 {
            font-size: 3rem;
        }

        p {
            font-size: 1.3rem;
        }
    }
}

.timeline-marker {
    margin-top: 27px;
}

@media (max-width: 768px) {
    .timeline-marker {
        margin-top: 12px;
    }
}

.heading {
    font-family: 'Trispace', monospace;
}

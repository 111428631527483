#footer {
    height: auto;
    text-align: center;

    h1 {
        font-family: 'Rubik Bubbles', cursive;
        font-size: 7rem; /* Default size for larger screens */
        text-shadow: 1px 4px 4px #003820;
        /* For Android shadow property */
        elevation: var(5);
        margin-bottom: 4rem;
    }

    a {
        font-size: 1.5rem;
    }

    .join {
        margin-bottom: 4rem;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .terms {
        margin-bottom: 4rem;
        width: 100%;
    }

    footer {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
            font-size: 3rem;
            margin-bottom: 1rem;
            margin-right: 1rem;

        /*    position at the far right */
            position: absolute;
            right: 0;
        /*    move up a bit from the bottom */
        }
    }
}

/* Adjust size for tablets */
@media (max-width: 768px) {
    #footer {
        h1 {
            font-size: 6rem;
        }

        footer {
            display: block;

            svg {
                margin-bottom: 0;
                margin-right: 0;
                position: static;
            }
        }
    }

}

/* Adjust size for mobile devices */
@media (max-width: 480px) {
    #footer h1 {
        font-size: 5rem;
    }
}

.newsletter {
    display: flex; /* Enables flexbox layout */
    align-items: center; /* Vertically aligns items in the container */

    input {
        width: 80%;
        font-size: 1.5rem;
        margin-left: 1rem;
    }

    button {
        margin-left: 1rem;
    }
}
